
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init({
    initClassName: 'aos-init',
    offset: 120,
    delay: 0,
    duration: 800,
    easing: 'ease',
});